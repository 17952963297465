import ACTIONS from './reducerTypes'

const DEFAULT = {
  produtos: [],
  isLoadingRastreioDonatario: false,
  isRastreioDonatarioLotePosting: false,
  isLoadingRastreioDonatario: false,
  error: null
}

export default function (state = DEFAULT, action) {
  switch (action.type) {
    case ACTIONS.RASTREIODONATARIO.LOAD.BEGIN:
      return { ...state, isLoadingRastreioDonatario: true, error: null }
    case ACTIONS.RASTREIODONATARIO.LOAD.SUCCESS:
      let novosProdutos = action.payload

      return {
        ...state,
        isLoadingRastreioDonatario: false,
        produtos: novosProdutos,
        error: null
      }

    case ACTIONS.RASTREIODONATARIO.LOAD.FAILURE:
      console.error('Reducer: Erro ao carregar estoque:', action.payload.error)
      return {
        ...state,
        isLoadingRastreioDonatario: false,
        error: action.payload.error || null
      }
    case ACTIONS.MOVRASTREIODONATARIO.LOTE.POST.BEGIN:
      return { ...state, isRastreioDonatarioLotePosting: true, error: null }
    case ACTIONS.MOVRASTREIODONATARIO.LOTE.POST.SUCCESS:
      return { ...state, isRastreioDonatarioLotePosting: false, error: null }
    case ACTIONS.MOVRASTREIODONATARIO.LOTE.POST.FAILURE:
      console.error('Reducer: Erro no post de lote:', action.payload.error)
      return {
        ...state,
        isRastreioDonatarioLotePosting: false,
        error: action.payload.error || null
      }
    default:
      return state
  }
}
