import { BtnSalvar } from 'components/Botao'
import Loading from 'components/loading'
import BoxProdutoCompradorDonatario from 'components/produto/boxProdutoRastreioCompradorDonatario'
import SearchBar from 'components/search-bar'
import { push } from 'connected-react-router'
import React from 'react'
import { CardDeck } from 'react-bootstrap'
import { connect } from 'react-redux'
import 'styles/produto.css'
import { getProdutosFiltrados } from 'util/importar-produtos-lote'
import { isArrayNotEmpty } from 'util/utils'

const searchOptions = {
  shouldSort: true,
  minMatchCharLength: 2,
  threshold: 0.4,
  keys: [
    { name: 'nomeProduto', weight: 2 },
    { name: 'descricaoProduto', weight: 0.6 },
    { name: 'categoriaEntity.nomeCategoria', weight: 0.4 }
  ]
}

class TelaRastreioDonatario extends React.Component {
  constructor (props) {
    super(props)

    this.elementoTopo = React.createRef()
    this.state = {
      produtosAlterados: {},
      categoria: {},
      hasSearch: false,
      subCategoria: {},
      inputFileFieldRef: Math.random().toString(36),
      estagio: 0
    }
    this.displayProdutosList = this.displayProdutosList.bind(this)
  }

  componentDidUpdate (prevProps) {
    const { produtosDonatario, isRastreioDonatarioLotePosting } = this.props

    if (JSON.stringify(produtosDonatario) !== JSON.stringify(prevProps.produtosDonatario)) {
      this.setProdutosFiltrados()
    }

    const estagio1Concluido = prevProps.isRastreioDonatarioLotePosting && !isRastreioDonatarioLotePosting
    if (estagio1Concluido) {
      this.setState({
        estagio: 0,
        produtosAlterados: {}
      })
    }
  }

  handleEdit = (produto) => {
    const { dispatch } = this.props
    localStorage.removeItem('produto')
    localStorage.setItem('produto', JSON.stringify(produto))
    setTimeout(() => {
      dispatch(push(`/produtos/edit-doacao/${produto.idProduto}`))
    }, 10)
  }

  displayProdutosList (produtos, onChange) {
    if (isArrayNotEmpty(produtos)) {
      return produtos.map((produto) => {
        const hasChange = this.state.produtosAlterados[produto.idProduto]
        if (hasChange) produto = { ...hasChange, ...produto }
        return (
          <BoxProdutoCompradorDonatario
            key={produto.idProduto}
            produto={{ produtos: [produto] }}
            onEdit={this.handleEdit}
            exibirEditar={this.state.estagio !== 0}
            onChange={onChange}
            hidePrice
            origin={produto.motivoAtualizacao}
          />
        )
      })
    }
    return <>Não existem produtos na categoria selecionada</>
  }

  getProdutosAlterados = () => Object.keys(this.state.produtosAlterados).map((el) => this.state.produtosAlterados[el])

  getProdutosFiltrados = (produtosDonatario) => {
    return getProdutosFiltrados(produtosDonatario, this.props.categorias, this.state.categoria, this.state.subCategoria)
  }

  setProdutosFiltrados = () => {
    this.setState({})
  }

  handleUpdateProduto = (dados, callback) => {
    const { produtosDonatario } = this.props
    const currentProdutosAlterados = this.state.produtosAlterados
    const produtoDonatarioAlterado = produtosDonatario.filter((el) => el.idProduto === dados.idProduto || (el.codigoInterno && el.codigoInterno === dados.codigoInterno))[0]

    if (produtoDonatarioAlterado) {
      currentProdutosAlterados[produtoDonatarioAlterado.idProduto] = {
        ...produtoDonatarioAlterado,
        ...dados,
        idEntregaProduto: produtoDonatarioAlterado.idEntregaProduto,
        id: produtoDonatarioAlterado.id
      }
      this.setState(
        {
          produtosAlterados: { ...currentProdutosAlterados }
        },
        callback
      )
    }
  }

  hasInvalidItens = () =>
    !Object.keys(this.state.produtosAlterados).length || Object.keys(this.state.produtosAlterados).some((x) => this.state.produtosAlterados[x].invalid === true)

  botaoSalvar = () => <BtnSalvar onSalvar={() => this.setState({ estagio: 1 })} disabled={this.hasInvalidItens()} />

  estagioAdicionarAlteracoes = (produtosDonatario) => {
    const onChangeItems = ({ result, query }) => {
      const hasSearch = query !== null && query.trim().length > 0

      this.setState({ hasSearch })
    }

    const produtosFiltrados = this.getProdutosFiltrados(produtosDonatario)

    return (
      <div>
        <SearchBar hasSearch={this.state.hasSearch} items={this.props.produtosDonatario} options={searchOptions} placeholder='Procurar em estoque' onSearchItems={onChangeItems} />
        <hr />
        <CardDeck style={{ justifyContent: 'center' }}>{this.displayProdutosList(produtosFiltrados, this.handleUpdateProduto)}</CardDeck>
      </div>
    )
  }

  render () {
    const { isLoadingRastreioDonatario, isRastreioDonatarioLotePosting, error, produtosDonatario } = this.props

    if (isLoadingRastreioDonatario || isRastreioDonatarioLotePosting) {
      return <Loading />
    }

    if (error) {
      return <div>Erro: {error}</div>
    }

    return <div ref={this.elementoTopo}>{this.estagioAdicionarAlteracoes(produtosDonatario)}</div>
  }
}

const mapStateToProps = (state) => {
  return {
    isRastreioDonatarioLotePosting: state.doacaoRastreioDonatario.isRastreioDonatarioLotePosting,
    produtosDonatario: state.doacaoRastreioDonatario.produtos,
    isLoadingRastreioDonatario: state.doacaoRastreioDonatario.isLoadingRastreioDonatario,
    error: state.doacaoRastreioDonatario.error
  }
}
const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(TelaRastreioDonatario)
