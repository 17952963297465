import { connectRouter } from 'connected-react-router'
import { reducer as toastrReducer } from 'react-redux-toastr'
import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import empresaReducer from 'store/empresa/reducer'
import estoqueCompradorReducer from 'store/rastreio-comprador/reducers'
import loginReducer from 'store/login/loginReducer'

import perfilReducer from 'store/perfil/reducers'

import { DoacaoAnuncioReducer, DoacaoEntregaDoadorReducer, DoacaoEntregaDonatarioReducer, DoacaoReservaReducer } from 'modules/negocios/doacao/reducers'
import { VendaAnuncioReducer, VendaEntregaAnuncianteReducer, VendaEntregaCompradorReducer } from 'modules/negocios/venda/reducers'
import DoacaoContratoReducer from 'store/contrato-doacao/reducer'
import VendaContratoReducer from 'store/contrato/reducers'
import importarProdutos from 'store/importar-produtos-lote/reducers'
import produtoReducer from 'store/produto/reducers'
import VendaReservaReducer from 'store/reserva/reducer'
import signupReducer from 'store/signup/reducers'
import globalReducer from './mainReducer'

import DoacaoRastreioDonatarioReducer from 'store/rastreio-donatario/reducers'

export default (history) =>
  combineReducers({
    main: globalReducer,
    login: loginReducer,
    cadastro: signupReducer,

    // Reducers libs
    router: connectRouter(history),
    form: formReducer,
    toastr: toastrReducer,
    empresa: empresaReducer,
    perfil: perfilReducer,

    // Reducers Venda
    vendaContrato: VendaContratoReducer,
    vendaAnuncio: VendaAnuncioReducer,
    vendaVitrine: VendaReservaReducer,
    vendaEntregaComprador: VendaEntregaCompradorReducer,
    vendaEntregaAnunciante: VendaEntregaAnuncianteReducer,

    // Estoque Comprador
    estoqueComprador: estoqueCompradorReducer,

    // Reducers Doacao
    doacaoContrato: DoacaoContratoReducer,
    doacaoAnuncio: DoacaoAnuncioReducer,
    doacaoReserva: DoacaoReservaReducer,
    doacaoEntregaDonatario: DoacaoEntregaDonatarioReducer,
    doacaoEntregaDoador: DoacaoEntregaDoadorReducer,
    doacaoRastreioDonatario: DoacaoRastreioDonatarioReducer,

    // Reducers produtos
    importarProdutos: importarProdutos,
    produto: produtoReducer
  })
