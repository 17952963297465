export default {
  CONTRATO: {
    LOAD: {
      ALL: {
        BEGIN: 'DOACAO_CONTRATO_LOAD_ALL_BEGIN',
        SUCCESS: 'DOACAO_CONTRATO_LOAD_ALL_SUCCESS',
        FAILURE: 'DOACAO_CONTRATO_LOAD_ALL_FAILURE'
      },
      ATIVOS: {
        BEGIN: 'DOACAO_CONTRATO_LOAD_ATIVOS_BEGIN',
        SUCCESS: 'DOACAO_CONTRATO_LOAD_ATIVOS_SUCCESS',
        FAILURE: 'DOACAO_CONTRATO_LOAD_ATIVOS_FAILURE'
      },
      INATIVOS: {
        BEGIN: 'DOACAO_CONTRATO_LOAD_INATIVOS_BEGIN',
        SUCCESS: 'DOACAO_CONTRATO_LOAD_INATIVOS_SUCCESS',
        FAILURE: 'DOACAO_CONTRATO_LOAD_INATIVOS_FAILURE'
      }
    },
    ATIVAR: {
      BEGIN: 'DOACAO_CONTRATO_ATIVAR_BEGIN',
      SUCCESS: 'DOACAO_CONTRATO_ATIVAR_SUCCESS',
      FAILURE: 'DOACAO_CONTRATO_ATIVAR_FAILURE'
    },
    DESATIVAR: {
      BEGIN: 'DOACAO_CONTRATO_DESATIVAR_BEGIN',
      SUCCESS: 'DOACAO_CONTRATO_DESATIVAR_SUCCESS',
      FAILURE: 'DOACAO_CONTRATO_DESATIVAR_FAILURE'
    },
    BYID: {
      BEGIN: 'DOACAO_CONTRATO_BYID_BEGIN',
      SUCCESS: 'DOACAO_CONTRATO_BYID_SUCCESS',
      FAILURE: 'DOACAO_CONTRATO_BYID_FAILURE'
    }
  }
}
