import { connect } from 'react-redux'
import DoacaoContrato from './doacaoContrato'
import VendaContrato from './vendaContrato'

const NovoContrato = ({ perfil, match }) => {
  const { doacao } = perfil
  const { doador, donatario } = doacao
  const isDoacao = doador || donatario

  if (isDoacao) {
    return <DoacaoContrato match={match} />
  } else {
    return <VendaContrato match={match} />
  }
}

const mapStateToProps = (state) => ({
  perfil: state.perfil
})

export default connect(mapStateToProps)(NovoContrato)
