import ACTIONS from './reducerTypes'

const DEFAULT = {
  produtos: [],
  isLoadingRastreioComprador: false,
  categorias: [],
  isLoadingCategorias: false,
  item: [],
  isRastreioCompradorLotePosting: false
}

export default function (state = DEFAULT, action) {
  if (typeof state === 'undefined') {
    return state
  }
  switch (action.type) {
    case ACTIONS.RASTREIOCOMPRADOR.LOAD.BEGIN:
      return { ...state, isLoadingRastreioComprador: true }
    case ACTIONS.RASTREIOCOMPRADOR.LOAD.SUCCESS:
      var novosProdutos = action.payload.itens
      return {
        ...state,
        isLoadingRastreioComprador: false,
        produtos: novosProdutos
      }
    case ACTIONS.RASTREIOCOMPRADOR.LOAD.FAILURE:
      return { ...state, isLoadingRastreioComprador: false }
    case ACTIONS.MOVRASTREIOCOMPRADOR.LOTE.POST.BEGIN:
      return { ...state, isRastreioCompradorLotePosting: true }
    case ACTIONS.MOVRASTREIOCOMPRADOR.LOTE.POST.SUCCESS:
      return {
        ...state,
        isRastreioCompradorLotePosting: false,
        item: action.payload
      }
    case ACTIONS.MOVRASTREIOCOMPRADOR.LOTE.POST.FAILURE:
      return { ...state, isRastreioCompradorLotePosting: false }

    default:
      return state
  }
}
