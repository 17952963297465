import React from 'react'
import { Container } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { loadGrupos, loadNonOngs } from 'store/empresa/actions'
import { loadProdutos } from 'store/produto/actions'

import SectionHandler from 'components/contrato/sectionHandler'
import { Etapa1, Etapa2 } from 'components/contrato/steps'
import { HorariosComponent } from 'components/contrato/steps/horarios'
import Loading from 'components/loading'
import ListarProdutos from 'components/produto/listProdutosDoacao'
import Refresh from 'components/refresh'
import { Screen } from 'components/style'
import TelaDeErro from 'components/telaDeErro'
import { TelaSucess } from 'components/telaSucess'
import { loadContratoById, postContratoDoacao, putContratoDoacao } from 'store/contrato-doacao/action'
import 'styles/contrato.css'
import { TipoHorarioEnum } from 'util/enums'
import { isArrayEmpty } from 'util/utils'
import { v4 as uuidv4 } from 'uuid'

class DoacaoContrato extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      horarios: [],
      tipoHorario: TipoHorarioEnum.SEMANAL,
      produtos: [],
      nome: '',
      descricao: '',
      ativo: true,
      assinaturas: [],
      grupos: [],
      isEstoqueProduto: false,
      isEdit: undefined,
      isDoacao: true,
      etapas: [
        {
          callback: this.handleEtapa1,
          ativa: true,
          hasErrorCallback: this.hasError1
        },
        {
          callback: this.handleEtapa2Assinantes,
          ativa: false,
          hasErrorCallback: this.hasError2
        },
        {
          callback: this.handleEtapa4Produtos,
          ativa: false,
          hasErrorCallback: this.hasError4
        },
        {
          callback: this.handleEtapa5,
          ativa: false,
          hasErrorCallback: this.hasError5
        }
      ],
      etapaInicial: 1,
      isLoadingData: true,
      isLoading: false,
      error: null,
      success: false
    }
  }

  async componentDidMount () {
    await this.init()

    const {
      dispatch,
      match: { params },
      perfil: { doacao }
    } = this.props
    const id = params.id
    const isEdit = !!id

    try {
      this.setState({ isLoadingData: true })
      if (isEdit) {
        const { payload } = await dispatch(loadContratoById(id))
        this.setState({
          ...payload,
          horarios: this.horariosToLocal(payload.horarios),
          isEdit: true,
          isLoadingData: false,
          isDoacao: !!(doacao.doador || doacao.donatario)
        })
      } else {
        this.setState({
          isLoadingData: false,
          isDoacao: !!(doacao.doador || doacao.donatario)
        })
      }
    } catch (error) {
      this.setState({ error })
    } finally {
      this.setState({ isLoadingData: false })
    }
  }

  getTime (d) {
    if (!d) return undefined
    const [hours, minutes] = d.split(':').map(Number)
    const format = (dt) => dt.toString().padStart(2, '0')
    return `${format(hours)}:${format(minutes)}`
  }

  horariosToLocal (horarios) {
    if (!horarios || isArrayEmpty(horarios)) return []

    const translateHorario = (h) => ({
      key: uuidv4(),
      dia: h.dInicioReserva,
      reserva: {
        inicioDia: h.dInicioReserva,
        inicio: this.getTime(h.inicioReserva),
        fimDia: h.dFimReserva,
        fim: this.getTime(h.fimReserva)
      },
      entrega: {
        inicioDia: h.dInicioEntrega,
        inicio: this.getTime(h.inicioEntrega),
        fimDia: h.dFimEntrega,
        fim: this.getTime(h.fimEntrega)
      }
    })

    return horarios.map((h) => translateHorario(h))
  }

  horariosToRemote (horarios) {
    if (!horarios || isArrayEmpty(horarios)) return []

    const translateHorario = (h) => ({
      dInicioReserva: h.reserva.inicioDia,
      inicioReserva: h.reserva.inicio,
      dFimReserva: h.reserva.fimDia,
      fimReserva: h.reserva.fim,
      dInicioEntrega: h.entrega.inicioDia,
      inicioEntrega: h.entrega.inicio,
      dFimEntrega: h.entrega.fimDia,
      fimEntrega: h.entrega.fim
    })

    return horarios.map((h) => translateHorario(h))
  }

  async init () {
    const { dispatch } = this.props

    try {
      this.setState({ isLoading: true })
      await Promise.all([dispatch(loadNonOngs()), dispatch(loadProdutos()), dispatch(loadGrupos())])
    } catch (error) {
      console.error('Erro ao carregar contrato:', error)
      this.setState({ error })
    } finally {
      this.setState({ isLoading: false })
    }
  }

  submitForm = async () => {
    this.setState({ success: false, error: null })

    const { isEdit } = this.state
    const { id, nome, tipoHorario, descricao, ativo, produtos, assinaturas, grupos, isEstoqueProduto } = this.state
    const horarios = this.horariosToRemote(this.state.horarios)

    const data = {
      id,
      nome,
      descricao,
      produtos,
      assinaturas,
      grupos,
      tipoHorario,
      horarios,
      ativo,
      isEstoqueProduto
    }

    const action = isEdit ? putContratoDoacao({ data }) : postContratoDoacao({ data })

    try {
      await action

      this.setState({ isLoading: true, success: true })
    } catch (error) {
      this.setState({ error, success: false })
    } finally {
      this.setState({ isLoading: false })
    }
  }

  handleEtapa1 = () => {
    const { nome, descricao, ativo } = this.state
    return (
      <Etapa1
        nome={nome}
        setNome={(value) => this.setState({ nome: value })}
        descricao={descricao}
        setDescricao={(value) => this.setState({ descricao: value })}
        ativo={ativo}
        setAtivo={(ativo) => this.setState({ ativo })}
        isDoacao={this.state.isDoacao}
      />
    )
  }

  handleEtapa2Assinantes = () => {
    const { isDoacao } = this.state
    const handleAddAssinatura = (event) => {
      const { assinantes } = this.props
      const idEmpresa = event.value
      const assinatura = Object.values(assinantes).find((a) => a.idEmpresa === idEmpresa)
      const assinaturas = [...this.state.assinaturas, assinatura]
      this.setState({ assinaturas })
    }

    const handleRemoveAssinatura = (idEmpresa) => {
      const assinaturas = this.state.assinaturas.filter((a) => a.idEmpresa !== idEmpresa)
      this.setState({ assinaturas })
    }

    const handleAddGrupo = (event) => {
      const id = event.value
      const grupo = this.props.grupos.find((g) => g.id === id)
      const grupos = [...this.state.grupos, grupo]
      this.setState({ grupos })
    }

    const handleRemoveGrupo = (id) => {
      const grupos = this.state.grupos.filter((g) => g.id !== id)
      this.setState({ grupos })
    }
    return (
      <Etapa2
        assinados={{
          assinaturas: this.state.assinaturas,
          grupos: this.state.grupos
        }}
        todos={{
          assinaturas: this.props.assinantes,
          grupos: this.props.grupos
        }}
        onAddAssinatura={handleAddAssinatura}
        onRemoveAssinatura={handleRemoveAssinatura}
        onAddGrupo={handleAddGrupo}
        onRemoveGrupo={handleRemoveGrupo}
        isDoacao={isDoacao}
      />
    )
  }

  handleEtapa4Produtos = () => {
    const { produtos, isEstoqueProduto, isDoacao } = this.state
    const { produtosCadastrados } = this.props

    const formatedProducts = produtos.map((produto) => {
      produto.unidade = produto.unidade || 'KG'
      return produto
    })
    return (
      <Container>
        <h1 className='margin-form'>Contrato de doação</h1>
        <h4>Produtos</h4>
        <br />
        <ListarProdutos
          cadastrados={produtosCadastrados}
          selecionados={formatedProducts}
          onAdd={(idProduto) => {
            const produto = produtosCadastrados.find((x) => x.idProduto === idProduto)
            const novoProduto = {
              quantidade: 0,
              nomeProduto: produto.nomeProduto,
              unidade: produto.unidade || 'KG',
              idProduto: produto.idProduto,
              produto
            }
            this.setState({ produtos: [...produtos, novoProduto] })
          }}
          onRemove={(idProduto) =>
            this.setState({
              produtos: [...produtos.filter((p) => p.idProduto !== idProduto)]
            })
          }
          onChange={(dados) => {
            const prods = [...produtos]
            const index = produtos.findIndex((x) => x.idProduto === dados.produto.idProduto)
            if (index !== -1) {
              prods[index] = { ...prods[index], unidade: dados.unidade }
              this.setState({ produtos: prods })
            }
          }}
          isEstoque={isEstoqueProduto}
          onChangeEstoque={(isEstoque) => {
            this.setState({ isEstoqueProduto: isEstoque })
          }}
        />
      </Container>
    )
  }

  handleEtapa5 = () => {
    const { horarios, tipoHorario } = this.state

    const handleChange = (item) => {
      this.setState({ horarios: item })
    }

    const handleChangeTipo = (tipoHorario) => {
      this.setState({ tipoHorario })
    }

    return (
      <form className='form' onSubmit={this.submitForm}>
        <div className='card container'>
          <div className='card-body'>
            <h1>Contrato de doação</h1>
            <h4>Selecione a disponibilidade:</h4>
            <HorariosComponent horarios={horarios} onChange={handleChange} tipo={tipoHorario} onChangeTipo={handleChangeTipo} />
          </div>
        </div>
      </form>
    )
  }

  hasError1 = () => {
    return this.state.nome === '' || this.state.nome === undefined
  }

  hasError2 = () => {
    return this.state.grupos.length === 0 && this.state.assinaturas.length === 0
  }

  hasError4 = () => {
    return false
  }

  hasError5 = () => {
    return false
  }

  render () {
    const { connection, isLoading, dispatch } = this.props
    const { isEdit, error, isLoadingData, success } = this.state
    const title = isEdit ? 'Contrato atualizado' : 'Contrato criado'

    if (error && !isLoading) {
      return (
        <Screen
          back={{
            to: '/doacao/contrato/listar/todos',
            title: 'Contrato'
          }}
        >
          <TelaDeErro
            error={error}
            callbackReturn={() => {
              this.setState({ error: null })
            }}
            message='Erro ao gravar o contrato'
          />
        </Screen>
      )
    }

    if (connection) {
      return <Refresh error={connection} dispatch={dispatch} />
    }

    if (isLoadingData || isLoading) {
      return <Loading />
    }

    if (success) {
      return (
        <TelaSucess title={title + ' com sucesso!'} to='/doacao/contrato/listar/todos' header='Contrato'>
          <div className='d-flex flex-column'>
            <Link className='my-2' to={'/doacao/contrato/listar/todos'}>
              Voltar para lista de contratos
            </Link>
            <br />
            <Link to='/'>Tela Principal</Link>
          </div>
        </TelaSucess>
      )
    }
    return (
      <Screen
        back={{
          to: '/doacao/contrato/listar/todos',
          title: 'Contrato'
        }}
      >
        <SectionHandler
          etapas={this.state.etapas.map((etapa, i) => ({
            ...etapa,
            etapa: i + 1
          }))}
          etapaInicial={this.state.etapaInicial}
          onChange={(etapa) => this.setState({ etapaInicial: etapa })}
          finalizar={() => this.submitForm()}
        />
      </Screen>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.doacaoContrato.isLoading,
    produtosCadastrados: state.produto.produtos,
    connection: state.main.connection,
    nongs: state.empresa.nongs,
    grupos: state.empresa.grupos,
    assinantes: state.empresa.nongs,
    contrato: state.doacaoContrato,
    perfil: state.perfil,
    error: state.doacaoContrato.error
  }
}
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  loadProdutos,
  loadGrupos,
  loadNonOngs
})
export default connect(mapStateToProps, mapDispatchToProps)(DoacaoContrato)
