export default {
  RASTREIODONATARIO: {
    LOAD: {
      BEGIN: 'MODDONATARIO_RASTREIOPENDENTE_LOAD_BEGIN',
      SUCCESS: 'MODDONATARIO_RASTREIOPENDENTE_LOAD_SUCCESS',
      FAILURE: 'MODDONATARIO_RASTREIOPENDENTE_LOAD_FAILURE'
    }
  },
  MOVRASTREIODONATARIO: {
    LOTE: {
      POST: {
        BEGIN: 'MOVIMENTODONATARIO_RASTREIOPENDENTE_POST_BEGIN',
        SUCCESS: 'MOVIMENTODONATARIO_RASTREIOPENDENTE_POST_SUCCESS',
        FAILURE: 'MOVIMENTODONATARIO_RASTREIOPENDENTE_POST_FAILURE'
      }
    }
  }
}
