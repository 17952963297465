import { requestApi } from 'util/utils'
import ACTIONS from './actionTypes'
import { API_ATIVAR, API_DESATIVAR, API_LOAD_ALL, API_LOAD_BYID, API_POST, API_PUT } from './url'

export const loadContratoTodos = () => {
  return (dispatch) => {
    dispatch({ type: ACTIONS.CONTRATO.LOAD.ALL.BEGIN })
    return requestApi(dispatch, API_LOAD_ALL)
      .fetch()
      .then((result) => {
        if (result && result.data && result.data.itens) {
          return dispatch({
            type: ACTIONS.CONTRATO.LOAD.ALL.SUCCESS,
            payload: result.data.itens
          })
        }
        return dispatch({
          type: ACTIONS.CONTRATO.LOAD.ALL.FAILURE,
          payload: result
        })
      })
      .catch((ex) => dispatch({ type: ACTIONS.CONTRATO.LOAD.ALL.FAILURE, payload: ex }))
  }
}

export const ativarContrato = (id) => {
  return (dispatch) => {
    dispatch({ type: ACTIONS.CONTRATO.ATIVAR.BEGIN, payload: id })
    return requestApi(dispatch, API_ATIVAR(id), 'POST')
      .fetch()
      .then(() => {
        return dispatch({ type: ACTIONS.CONTRATO.ATIVAR.SUCCESS, payload: id })
      })
      .catch((ex) => dispatch({ type: ACTIONS.CONTRATO.ATIVAR.FAILURE, payload: ex }))
  }
}

export const inativarContrato = (id) => {
  return (dispatch) => {
    dispatch({ type: ACTIONS.CONTRATO.DESATIVAR.BEGIN, payload: id })
    return requestApi(dispatch, API_DESATIVAR(id), 'POST')
      .fetch()
      .then(() => {
        return dispatch({
          type: ACTIONS.CONTRATO.DESATIVAR.SUCCESS,
          payload: id
        })
      })
      .catch((ex) => dispatch({ type: ACTIONS.CONTRATO.DESATIVAR.FAILURE, payload: ex }))
  }
}

export const postContratoDoacao = async ({ data }) => {
  const body = {
    nome: data.nome,
    descricao: data.descricao,
    ativo: data.ativo,
    produtos: data.produtos,
    horarios: data.horarios,
    assinaturas: data.assinaturas,
    isEstoqueProduto: data.isEstoqueProduto,
    tipoHorario: data.tipoHorario,
    grupos: data.grupos.map((grupo) => ({ idGrupo: grupo.id }))
  }

  try {
    const result = await requestApi(null, API_POST, 'POST', body).fetch()

    if (result && result.data) {
      return result.data
    } else {
      const errorMessage = result?.data?.message || 'Erro ao criar contrato de doação'
      return Promise.reject(new Error(errorMessage))
    }
  } catch (error) {
    return Promise.reject(error.response || 'Erro na resposta da API')
  }
}

export const putContratoDoacao = async ({ data }) => {
  const body = {
    nome: data.nome,
    descricao: data.descricao,
    ativo: data.ativo,
    produtos: data.produtos,
    horarios: data.horarios,
    id: data.id,
    assinaturas: data.assinaturas,
    isEstoqueProduto: data.isEstoqueProduto,
    tipoHorario: data.tipoHorario,
    grupos: data.grupos.map((grupo) => ({ idGrupo: grupo.id || grupo.idGrupo }))
  }

  try {
    const result = await requestApi(null, API_PUT, 'PATCH', body).fetch()

    if (result && result.data) {
      return result.data.id
    } else {
      const errorMessage = result?.data?.message || 'Erro ao atualizar contrato de doação'
      return Promise.reject(new Error(errorMessage))
    }
  } catch (error) {
    return Promise.reject(error.response || 'Erro na resposta da API')
  }
}

export const loadContratoById = (id) => {
  return (dispatch) => {
    dispatch({ type: ACTIONS.CONTRATO.BYID.BEGIN })
    return requestApi(dispatch, API_LOAD_BYID(id))
      .fetch()
      .then((result) => {
        if (result && result.data) {
          return dispatch({
            type: ACTIONS.CONTRATO.BYID.SUCCESS,
            payload: result.data
          })
        }
        return dispatch({
          type: ACTIONS.CONTRATO.BYID.FAILURE,
          payload: result
        })
      })
      .catch((ex) => dispatch({ type: ACTIONS.CONTRATO.BYID.FAILURE, payload: ex }))
  }
}
