import React from 'react'
import { Container } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { loadContratoById, postContrato, putContrato } from 'store/contrato/action'
import { loadGrupos, loadNonOngs } from 'store/empresa/actions'
import { loadProdutos } from 'store/produto/actions'

import SectionHandler from 'components/contrato/sectionHandler'
import { Etapa1, Etapa2, Etapa3 } from 'components/contrato/steps'
import { HorariosComponent } from 'components/contrato/steps/horarios'
import Loading from 'components/loading'
import ListarProdutos from 'components/produto/listProdutosContrato'
import Refresh from 'components/refresh'
import { Screen } from 'components/style'
import TelaDeErro from 'components/telaDeErro'
import { TelaSucess } from 'components/telaSucess'
import 'styles/contrato.css'
import { TipoHorarioEnum } from 'util/enums'
import { formasPagamento } from 'util/pagamentos'
import { isArrayEmpty } from 'util/utils'
import { v4 as uuidv4 } from 'uuid'

class VendaContrato extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      horarios: [],
      tipoHorario: TipoHorarioEnum.SEMANAL,
      produtos: [],
      formasDePagamento: [],
      nome: '',
      descricao: '',
      ativo: true,
      assinaturas: [],
      grupos: [],
      isEstoqueProduto: false,
      isPrecoProduto: false,
      isEdit: undefined,
      etapas: [
        {
          callback: this.handleEtapa1,
          ativa: true,
          hasErrorCallback: this.hasError1
        },
        {
          callback: this.handleEtapa2Assinantes,
          ativa: false,
          hasErrorCallback: this.hasError2
        },
        {
          callback: this.handleEtapa4Produtos,
          ativa: false,
          hasErrorCallback: this.hasError4
        },
        {
          callback: this.handleEtapa5,
          ativa: false,
          hasErrorCallback: this.hasError5
        }
      ],
      etapaInicial: 1,
      isLoadingData: true,
      isLoading: false,
      error: null
    }
  }

  async componentDidMount () {
    await this.init()
    const {
      dispatch,
      match: { params }
    } = this.props
    const id = params.id
    const isEdit = !!id

    try {
      this.state.etapas.splice(2, 0, {
        callback: this.handleEtapa3FormasDePagamento,
        ativa: false,
        hasErrorCallback: this.hasError3
      })
      if (isEdit) {
        const { payload } = await dispatch(loadContratoById(id))
        this.setState({
          ...payload,
          horarios: this.horariosToLocal(payload.horarios),
          isEdit: true
        })
      }
    } catch (error) {
      this.setState({ error })
    } finally {
      this.setState({ isLoadingData: false })
    }
  }

  getTime (d) {
    if (d === null || d === undefined || d === 0) return undefined
    const [hours, minutes] = d.split(':').map(Number)
    const format = (dt) => dt.toString().padStart(2, '0')
    return `${format(hours)}:${format(minutes)}`
  }

  horariosToLocal (horarios) {
    if (!horarios || isArrayEmpty(horarios)) return []

    const translateHorario = (h) => ({
      key: uuidv4(),
      dia: h.dInicioReserva,
      reserva: {
        inicioDia: h.dInicioReserva,
        inicio: this.getTime(h.inicioReserva),
        fimDia: h.dFimReserva,
        fim: this.getTime(h.fimReserva)
      },
      entrega: {
        inicioDia: h.dInicioEntrega,
        inicio: this.getTime(h.inicioEntrega),
        fimDia: h.dFimEntrega,
        fim: this.getTime(h.fimEntrega)
      }
    })

    return horarios.map((h) => translateHorario(h))
  }

  horariosToRemote (horarios) {
    if (!horarios || isArrayEmpty(horarios)) return []

    const translateHorario = (h) => ({
      dInicioReserva: h.reserva.inicioDia,
      inicioReserva: h.reserva.inicio,
      dFimReserva: h.reserva.fimDia,
      fimReserva: h.reserva.fim,
      dInicioEntrega: h.entrega.inicioDia,
      inicioEntrega: h.entrega.inicio,
      dFimEntrega: h.entrega.fimDia,
      fimEntrega: h.entrega.fim
    })

    return horarios.map((h) => translateHorario(h))
  }

  init () {
    const { dispatch } = this.props
    dispatch(loadNonOngs())
    dispatch(loadProdutos())
    dispatch(loadGrupos())
  }

  submitForm = async () => {
    this.setState({ success: false, error: null })
    const { dispatch } = this.props
    const { isEdit, ...stateData } = this.state
    const horarios = this.horariosToRemote(this.state.horarios)
    const data = { ...stateData, horarios }

    try {
      this.setState({ isLoading: true })
      const result = isEdit ? await dispatch(putContrato({ data })) : await dispatch(postContrato({ data }))
      this.setState({ success: true })
    } catch (error) {
      console.error('Erro ao submeter formulário:', error)
      this.setState({ error, success: false })
    } finally {
      this.setState({ isLoading: false })
    }
  }

  handleEtapa1 = () => {
    const { nome, descricao, ativo } = this.state
    return (
      <Etapa1
        nome={nome}
        setNome={(value) => this.setState({ nome: value })}
        descricao={descricao}
        setDescricao={(value) => this.setState({ descricao: value })}
        ativo={ativo}
        setAtivo={(ativo) => this.setState({ ativo })}
      />
    )
  }

  handleEtapa2Assinantes = () => {
    const handleAddAssinatura = (event) => {
      const { assinantes } = this.props
      const idEmpresa = event.value
      const assinatura = Object.values(assinantes).find((a) => a.idEmpresa === idEmpresa)
      const assinaturas = [...this.state.assinaturas, assinatura]
      this.setState({ assinaturas })
    }

    const handleRemoveAssinatura = (idEmpresa) => {
      const assinaturas = this.state.assinaturas.filter((a) => a.idEmpresa !== idEmpresa)
      this.setState({ assinaturas })
    }

    const handleAddGrupo = (event) => {
      const id = event.value
      const grupo = this.props.grupos.find((g) => g.id === id)
      const grupos = [...this.state.grupos, grupo]
      this.setState({ grupos })
    }

    const handleRemoveGrupo = (id) => {
      const grupos = this.state.grupos.filter((g) => g.id !== id)
      this.setState({ grupos })
    }
    return (
      <Etapa2
        assinados={{
          assinaturas: this.state.assinaturas,
          grupos: this.state.grupos
        }}
        todos={{
          assinaturas: this.props.assinantes,
          grupos: this.props.grupos
        }}
        onAddAssinatura={handleAddAssinatura}
        onRemoveAssinatura={handleRemoveAssinatura}
        onAddGrupo={handleAddGrupo}
        onRemoveGrupo={handleRemoveGrupo}
      />
    )
  }

  handleEtapa3FormasDePagamento = () => {
    const handleAddFormaDePagamento = (event) => {
      const idPagamento = event.value
      const pagamento = formasPagamento.find((produto) => produto.idFormaPagamento === idPagamento)
      const pagamentos = [
        ...this.state.formasDePagamento,
        {
          id: idPagamento,
          idFormaPagamento: idPagamento,
          formaDePagamento: pagamento
        }
      ]
      this.setState({ formasDePagamento: pagamentos })
    }

    const handleRemoveFormaDePagamento = (idPagamento) => {
      const pagamentos = this.state.formasDePagamento.filter((pagamento) => pagamento.idFormaPagamento !== idPagamento)
      this.setState({ formasDePagamento: pagamentos })
    }

    const handleChangeFormaDePagamento = (idFormaPagamento, data) => {
      const index = this.state.formasDePagamento.findIndex((pagamento) => pagamento.idFormaPagamento === idFormaPagamento)
      const formasDePagamento = [...this.state.formasDePagamento]
      formasDePagamento[index] = { ...formasDePagamento[index], dados: data }
      this.setState({ formasDePagamento: formasDePagamento })
    }
    return (
      <Etapa3
        formasDePagamentoSelecionadas={this.state.formasDePagamento || []}
        formasDePagamento={formasPagamento || []}
        onAdd={handleAddFormaDePagamento}
        onRemove={handleRemoveFormaDePagamento}
        onChange={handleChangeFormaDePagamento}
      />
    )
  }

  handleEtapa4Produtos = () => {
    const { produtos, isEstoqueProduto, isPrecoProduto } = this.state
    const { produtosCadastrados } = this.props

    const formatedProducts = produtos.map((produto) => ({
      ...produto,
      preco: produto.preco || 0,
      unidade: produto.unidade || 'KG'
    }))

    return (
      <Container>
        <h1 className='margin-form'>Contrato de venda</h1>
        <h4>Produtos</h4>
        <br />
        <ListarProdutos
          cadastrados={produtosCadastrados}
          selecionados={formatedProducts}
          onAdd={(idProduto) => {
            const produto = produtosCadastrados.find((x) => x.idProduto === idProduto)
            const novoProduto = {
              preco: 0,
              quantidade: 0,
              nomeProduto: produto.nomeProduto,
              unidade: produto.unidade || 'KG',
              idProduto: produto.idProduto,
              produto
            }
            this.setState((prevState) => ({
              produtos: [...prevState.produtos, novoProduto]
            }))
          }}
          onRemove={(idProduto) => {
            this.setState((prevState) => ({
              produtos: prevState.produtos.filter((p) => p.idProduto !== idProduto)
            }))
          }}
          onChange={(dados) => {
            this.setState((prevState) => ({
              produtos: prevState.produtos.map((p) => (p.idProduto === dados.produto.idProduto ? { ...p, unidade: dados.unidade, preco: dados.preco } : p))
            }))
          }}
          isEstoque={isEstoqueProduto}
          onChangeEstoque={(isEstoque) => {
            this.setState({ isEstoqueProduto: isEstoque })
          }}
          isPreco={isPrecoProduto}
          onChangePreco={(isPreco) => {
            this.setState({ isPrecoProduto: isPreco })
          }}
          isSale
        />
      </Container>
    )
  }

  handleEtapa5 = () => {
    const { horarios, tipoHorario } = this.state

    const handleChange = (item) => {
      this.setState({ horarios: item })
    }

    const handleChangeTipo = (tipoHorario) => {
      this.setState({ ...this.state, tipoHorario })
    }

    return (
      <form className='form' onSubmit={() => this.submitForm.bind(this)}>
        <div className='card container'>
          <div className='card-body'>
            <h1>Contrato de venda</h1>
            <h4>Selecione a disponibilidade:</h4>
            <HorariosComponent horarios={horarios} onChange={handleChange} tipo={tipoHorario} onChangeTipo={handleChangeTipo} />
          </div>
        </div>
      </form>
    )
  }

  hasError1 = () => {
    return this.state.nome === '' || this.state.nome === undefined
  }

  hasError2 = () => {
    return this.state.grupos.length === 0 && this.state.assinaturas.length === 0
  }

  hasError3 = () => {
    return this.state.formasDePagamento === undefined || this.state.formasDePagamento.length === 0
  }

  hasError4 = () => {
    return false
  }

  hasError5 = () => {
    return false
  }

  render () {
    const { connection, isLoading, dispatch, isLoadingBtn, success } = this.props
    const { isEdit, error, isLoadingData } = this.state
    const title = isEdit ? 'Contrato atualizado' : 'Contrato criado'

    if (error && !isLoading) {
      return <TelaDeErro error={error} callbackReturn={() => {}} message='Ocorreu um erro:' />
    }

    if (connection) {
      return <Refresh error={connection} dispatch={dispatch} />
    }

    if (isLoadingData || isLoadingBtn) {
      return <Loading />
    }

    if (success) {
      return (
        <TelaSucess title={title + ' com sucesso!'} to={'/venda/contrato/listar/todos'} header='Contrato'>
          <div className='d-flex flex-column'>
            <Link className='my-2' to={'/venda/contrato/listar/todos'}>
              Voltar para lista de contratos
            </Link>
            <br />
            <Link to='/'>Tela Principal</Link>
          </div>
        </TelaSucess>
      )
    }
    return (
      <Screen
        back={{
          to: '/venda/contrato/listar/todos',
          title: 'Contrato'
        }}
      >
        <SectionHandler
          etapas={this.state.etapas.map((etapa, i) => ({
            ...etapa,
            etapa: i + 1
          }))}
          etapaInicial={this.state.etapaInicial}
          onChange={(etapa) => this.setState({ etapaInicial: etapa })}
          finalizar={this.submitForm}
        />
      </Screen>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.vendaContrato.isLoading,
    produtosCadastrados: state.produto.produtos,
    connection: state.main.connection,
    nongs: state.empresa.nongs,
    grupos: state.empresa.grupos,
    assinantes: state.empresa.nongs,
    error: state.vendaContrato.error,
    success: state.vendaContrato.success
  }
}
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  handleSubmitNew: postContrato,
  loadProdutos,
  loadGrupos,
  loadNonOngs
})
export default connect(mapStateToProps, mapDispatchToProps)(VendaContrato)
