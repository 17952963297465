import { StepMotivoRegistro, StepNovaQuantidade } from 'components/estoque/steps'
import { Screen } from 'components/style'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { editRastreioComprador } from 'store/rastreio-comprador/actions'

function EditarProduto () {
  const { id } = JSON.parse(localStorage.getItem('produto'))
  const history = useHistory()
  const dispatch = useDispatch()

  const produtoBase = JSON.parse(localStorage.getItem('produto'))

  const [position, setPosition] = useState(1)
  const [produto, setProduto] = useState({
    motivo: undefined,
    estoque: produtoBase.estoque,
    nomeProduto: produtoBase.nomeProduto,
    idProduto: produtoBase.idProduto
  })

  const positions = [!produto.motivo, !produto.estoque]

  const isFormInvalid = positions.some((validation) => !!validation)

  const finish = async (estoque) => {
    setProduto((prevProduto) => ({ ...prevProduto, estoque }))
    if (isFormInvalid) {
      const index = positions.findIndex((validation) => validation)
      setPosition(index + 1)
      return
    }

    const payload = {
      id,
      quantidadeAtual: estoque,
      motivoAtualizacao: produto.motivo
    }
    await dispatch(editRastreioComprador(payload))
    history.push('/produtos')
  }

  function next (payload) {
    setProduto((prevProduto) => ({ ...prevProduto, ...payload }))
    setPosition(2)
  }
  function onChange (field, value) {
    setProduto((prevProduto) => ({ ...prevProduto, [field]: value }))
  }
  function CurrentStep () {
    switch (position) {
      case 1:
        return <StepMotivoRegistro motivoBase={produto.motivo} next={next} nome={produto.nome} onChange={onChange} />
      case 2:
        return (
          <StepNovaQuantidade
            finish={finish}
            back={(payload) => {
              setProduto((prevProduto) => ({ ...prevProduto, ...payload }))
              setPosition(1)
            }}
            estoque={produto.estoque}
          />
        )
    }
  }

  function Positions () {
    return positions.map((callbackError, index) => {
      let classes = callbackError ? 'button-circleRed' : 'button-circle'
      if (index !== 4) classes += ' mr-1 mr-sm-3'
      return (
        <div onClick={() => setPosition(index + 1)} key={index} style={{ border: '2px solid #eee', cursor: 'pointer' }} className={classes} variant='outlined'>
          <div className=' '>{index + 1}</div>
        </div>
      )
    })
  }

  return (
    <Screen back={{ to: '/produtos', title: 'Menu' }}>
      <div style={{ overflowX: 'hidden' }}>
        <div className='d-flex justify-content-center flex-wrap mt-4'>
          <Positions />
        </div>
        <div className='mt-4 mx-3'>
          <CurrentStep />
        </div>
      </div>
    </Screen>
  )
}

export default EditarProduto
