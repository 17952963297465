import { URL_VENDA_CONTRATO_BASE } from 'store/urlsConstants'
import { requestApi } from 'util/utils'
import ACTIONS from './actionTypes'
import { API_ATIVAR, API_DESATIVAR, API_LOAD_ALL, API_LOAD_BYID } from './url'

const handleApiError = (error, dispatch, failureAction) => {
  let apiError
  if (error.response && error.response.data) {
    apiError = error.response.data
  } else {
    apiError = [{ campo: 'Erro', mensagem: error.message || 'Erro desconhecido.' }]
  }
  dispatch({ type: failureAction, payload: apiError })
}

export const resetSuccess = () => {
  return (dispatch) => {
    dispatch({ type: ACTIONS.CONTRATOVENDA.RESET_SUCCESS })
  }
}

export const loadContratoTodos = () => {
  return (dispatch) => {
    dispatch({ type: ACTIONS.CONTRATOVENDA.LOAD.ALL.BEGIN })
    return requestApi(dispatch, API_LOAD_ALL)
      .fetch()
      .then((result) => {
        if (result && result.data && result.data.itens) {
          return dispatch({
            type: ACTIONS.CONTRATOVENDA.LOAD.ALL.SUCCESS,
            payload: result.data.itens
          })
        }
        return dispatch({
          type: ACTIONS.CONTRATOVENDA.LOAD.ALL.FAILURE,
          payload: result
        })
      })
      .catch((ex) => dispatch({ type: ACTIONS.CONTRATOVENDA.LOAD.ALL.FAILURE, payload: ex }))
  }
}

export const ativarContrato = (id) => {
  return (dispatch) => {
    dispatch({ type: ACTIONS.CONTRATOVENDA.ATIVAR.BEGIN, payload: id })
    return requestApi(dispatch, API_ATIVAR(id))
      .fetch()
      .then(() => {
        return dispatch({ type: ACTIONS.CONTRATOVENDA.ATIVAR.SUCCESS, payload: id })
      })
      .catch((ex) => dispatch({ type: ACTIONS.CONTRATOVENDA.ATIVAR.FAILURE, payload: ex }))
  }
}

export const inativarContrato = (id) => {
  return (dispatch) => {
    dispatch({ type: ACTIONS.CONTRATOVENDA.DESATIVAR.BEGIN, payload: id })
    return requestApi(dispatch, API_DESATIVAR(id))
      .fetch()
      .then(() => {
        return dispatch({
          type: ACTIONS.CONTRATOVENDA.DESATIVAR.SUCCESS,
          payload: id
        })
      })
      .catch((ex) => dispatch({ type: ACTIONS.CONTRATOVENDA.DESATIVAR.FAILURE, payload: ex }))
  }
}

export const postContrato = ({ data }) => {
  const body = {
    ...data,
    horarios: data.horarios,
    formasDePagamento: data.formasDePagamento.map((fp) => ({
      idFormaPagamento: fp.id,
      dados: JSON.stringify(fp.dados)
    })),
    grupos: data.grupos.map((grupo) => ({ idGrupo: grupo.id }))
  }

  return async (dispatch) => {
    dispatch({ type: ACTIONS.CONTRATOVENDA.POST.BEGIN })
    try {
      const result = await requestApi(dispatch, URL_VENDA_CONTRATO_BASE, 'POST', body).fetch()
      if (result && result.data) {
        dispatch({
          type: ACTIONS.CONTRATOVENDA.POST.SUCCESS,
          payload: result.data
        })
        return result.data
      } else {
        const errorMessage = result?.data?.message || 'Erro ao efetuar o cadastro'
        return Promise.reject(new Error(errorMessage))
      }
    } catch (error) {
      handleApiError(error, dispatch, ACTIONS.CONTRATOVENDA.POST.FAILURE)
      return Promise.reject(error)
    }
  }
}

export const putContrato = ({ data }) => {
  const body = {
    nome: data.nome,
    descricao: data.descricao,
    ativo: data.ativo,
    produtos: data.produtos,
    horarios: data.horarios,
    id: data.id,
    assinaturas: data.assinaturas,
    isEstoqueProduto: data.isEstoqueProduto,
    isPrecoProduto: data.isPrecoProduto,
    tipoHorario: data.tipoHorario,
    formasDePagamento: data.formasDePagamento.map((fp) => ({
      idFormaPagamento: fp.idFormaPagamento,
      dados: JSON.stringify(fp.dados)
    })),
    grupos: data.grupos.map((grupo) => ({ idGrupo: grupo.id || grupo.idGrupo }))
  }

  return async (dispatch) => {
    dispatch({ type: ACTIONS.CONTRATOVENDA.PUT.BEGIN, payload: body })
    try {
      const result = await requestApi(dispatch, URL_VENDA_CONTRATO_BASE, 'PATCH', body).fetch()
      if (result && result.data) {
        dispatch({
          type: ACTIONS.CONTRATOVENDA.PUT.SUCCESS,
          payload: result.data
        })
        return result.data
      } else {
        const errorMessage = result?.data?.message || 'Erro ao atualizar o cadastro'
        return Promise.reject(new Error(errorMessage))
      }
    } catch (error) {
      handleApiError(error, dispatch, ACTIONS.CONTRATOVENDA.PUT.FAILURE)
      return Promise.reject(error)
    }
  }
}

export const loadContratoById = (id) => {
  return (dispatch) => {
    dispatch({ type: ACTIONS.CONTRATOVENDA.BYID.BEGIN })
    return requestApi(dispatch, API_LOAD_BYID(id))
      .fetch()
      .then((result) => {
        if (result && result.data) {
          return dispatch({
            type: ACTIONS.CONTRATOVENDA.BYID.SUCCESS,
            payload: result.data
          })
        }
        return dispatch({
          type: ACTIONS.CONTRATOVENDA.BYID.FAILURE,
          payload: result
        })
      })
      .catch((ex) => dispatch({ type: ACTIONS.CONTRATOVENDA.BYID.FAILURE, payload: ex }))
  }
}
