import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Container } from 'react-bootstrap'
import { isArrayEmpty, isArrayNotEmpty } from 'util/utils'

const extractError = (error) => {
  if (isArrayNotEmpty(error?.data)) return error?.data
  if (isArrayNotEmpty(error?.response)) return error?.response
  if (isArrayNotEmpty(error?.response?.data)) return error?.response?.data
  return error
}

const TelaDeErro = ({ error, callbackReturn, callbackReset, back, message, dispatch }) => {
  const realError = extractError(error)

  if (realError === 'ECONNABORTED') {
    return (
      <Container>
        <h1>O Servidor demorou muito a responder.</h1>
        <p>A demora em acessar o servidor pode ser algo momentâneo, por isso, tente novamente.</p>
        <p>Caso o erro persista, entre em contato com o suporte.</p>
        <Button
          className='btn btn-primary saveadd-primary-color'
          onClick={() => {
            dispatch({ type: 'RESETCONNECTION' })
            callbackReturn()
          }}
        >
          Tentar novamente
        </Button>
        <Button
          className='btn btn-primary saveadd-primary-color'
          onClick={() => {
            dispatch({ type: 'RESETCONNECTION' })
            callbackReset && callbackReset()
            callbackReturn()
          }}
        >
          Reiniciar
        </Button>
      </Container>
    )
  }

  if (isArrayEmpty(realError))
    return (
      <Container>
        <h1>Erro não identificado</h1>
        <p>Contate o suporte tecnico com a informação abaixo:</p>
        <p>{JSON.stringify(realError)}</p>
        <Button
          onClick={() => {
            callbackReturn()
          }}
        >
          Voltar
        </Button>
      </Container>
    )

  return (
    <Container>
      <h1>{message}</h1>
      {realError.map((erro, index) => {
        return (
          <p key={JSON.stringify(erro)}>
            {index + 1}) {erro.campo} - {erro.mensagem}
          </p>
        )
      })}
      <Button
        onClick={() => {
          callbackReturn()
        }}
      >
        Voltar
      </Button>
    </Container>
  )
}

TelaDeErro.propTypes = {
  error: PropTypes.any.isRequired,
  callbackReturn: PropTypes.func.isRequired,
  callbackReset: PropTypes.func,
  message: PropTypes.string.isRequired
}

const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(null, mapDispatchToProps)(TelaDeErro)
