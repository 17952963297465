export default {
  RASTREIOCOMPRADOR: {
    LOAD: {
      BEGIN: 'MODCOMPRADOR_RASTREIOPENDENTE_LOAD_BEGIN',
      SUCCESS: 'MODCOMPRADOR_RASTREIOPENDENTE_LOAD_SUCCESS',
      FAILURE: 'MODCOMPRADOR_RASTREIOPENDENTE_LOAD_FAILURE'
    }
  },
  MOVRASTREIOCOMPRADOR: {
    LOTE: {
      POST: {
        BEGIN: 'MOVIMENTOCOMPRADOR_RASTREIOPENDENTE_LOTE_POST_BEGIN',
        SUCCESS: 'MOVIMENTOCOMPRADOR_RASTREIOPENDENTE_LOTE_POST_SUCCESS',
        FAILURE: 'MOVIMENTOCOMPRADOR_RASTREIOPENDENTE_LOTE_POST_FAILURE'
      }
    }
  }
}
