import { faUser } from '@fortawesome/free-regular-svg-icons'
import { isObject } from 'lodash-es'
import { useState } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Container, Navbar } from 'react-bootstrap'

import { limparSelecao } from 'store/perfil/actions'

import { getTipoPerfil } from 'util/tipoPerfil'
import { formataCPF } from 'util/utils'

const RenderUserName = ({ userName }) => {
  return <span className='loggedUser p-2'>{userName || ' - '}</span>
}

const RenderBack = ({ back }) => {
  if (isObject(back)) {
    return (
      <NavLink className='breadcrumb-item' to={back.to}>
        <FontAwesomeIcon icon={faAngleLeft} size='lg' />

        {back.title}
      </NavLink>
    )
  }

  return <></>
}

function Header ({ login, back, title, tabSelectedMainMenu, dispatch, perfil }) {
  const [navExpanded, setNavExpanded] = useState(false)
  const tipoPerfil = getTipoPerfil(perfil)

  return (
    <Navbar
      onToggle={() => setNavExpanded(!navExpanded)}
      expanded={navExpanded}
      expand='lg'
      bg='dark'
      variant='dark'
      className={`sticky-top ${tabSelectedMainMenu === 1 ? 'saveadd-secondary-color' : 'saveadd-primary-color'} fixed`}
    >
      <Container fluid>
        <Navbar.Brand>{title || <RenderBack back={back} />}</Navbar.Brand>
        <Navbar.Toggle aria-controls='userNavbar'>
          <FontAwesomeIcon icon={faUser} size='lg' />
        </Navbar.Toggle>
        <Navbar.Collapse id='userNavbar' className='justify-content-end'>
          <div className={`d-flex align-items-center ${navExpanded && window.innerWidth < 992 ? 'flex-column' : ''}`}>
            <div className={`d-flex align-items-center ${navExpanded && window.innerWidth < 992 ? 'w-100 justify-content-end' : ''}`}>
              <span className='p-2 me-2 d-flex align-items-center'>
                <span className='d-none d-md-inline'>Perfil&nbsp; </span> {tipoPerfil}
              </span>
              <RenderUserName userName={formataCPF(login.nomeUsuario)} />
              <Button onClick={() => dispatch(limparSelecao())} className='nav-link p-2 text-white ms-2 btn-sm'>
                Trocar Perfil
              </Button>
              <NavLink to='/logout' className='nav-link p-2 text-white ms-2' activeClassName='active'>
                Sair
              </NavLink>
            </div>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

const mapStateToProps = (state) => ({
  ...state,
  tabSelectedMainMenu: state.main.tabSelectedMainMenu,
  perfil: state.perfil
})
const mapDispatchToProps = (dispatch) => ({ dispatch })
export default connect(mapStateToProps, mapDispatchToProps)(Header)
