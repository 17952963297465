import React from 'react'
import PropTypes from 'prop-types'

const EtapaUm = ({ nome, setNome, descricao, setDescricao, ativo, setAtivo, isDoacao }) => {
  const maxNomeLength = 50
  const maxDescricaoLength = 5000

  return (
    <>
      <h1 className='mt-2'>Contrato de {isDoacao ? 'doação' : 'venda'}</h1>
      <div className='form-group'>
        <h4>Dados do contrato</h4>
        <label name='inputTituloRelacionamento' className='margin-form2x' htmlFor='nome'>
          Título do relacionamento: *
        </label>
        <input
          type='text'
          value={nome || ''}
          id='nome'
          name='nome'
          className='form-control'
          placeholder='Digite o título do relacionamento'
          autoComplete='off'
          aria-describedby='Nome do contrato'
          required
          autoFocus
          maxLength={maxNomeLength}
          onChange={(e) => setNome && setNome(e.target.value)}
        />
        <div className='limit-chars-textarea'>
          Você pode digitar
          <span className={`charsRemaineds ${maxNomeLength - (nome ? nome.length : 0) === 0 ? 'text-danger' : 'text-success'}`}>
            {` ${maxNomeLength - (nome ? nome.length : 0)} `}
          </span>
          caracteres
        </div>
      </div>
      <div name='inputDescricao' className='form-group'>
        <label htmlFor='descricao'>Descrição:</label>
        <textarea
          id='Descricao'
          value={descricao || ''}
          name='Descricao'
          rows={8}
          maxLength={maxDescricaoLength}
          className='form-control'
          placeholder='Faça uma breve descrição'
          autoComplete='off'
          required
          draggable='false'
          onChange={(e) => setDescricao && setDescricao(e.target.value)}
        />
        <div className='limit-chars-textarea'>
          Você pode digitar
          <span className={`charsRemaineds ${maxDescricaoLength - descricao.length === 0 ? 'text-danger' : 'text-success'}`}>{` ${maxDescricaoLength - descricao.length} `}</span>
          caracteres
        </div>
      </div>
      <div className='form-check'>
        <label className='form-check-label align-middle' htmlFor='Ativo'>
          <input className='form-check-input' type='checkbox' id='Ativo' name='Ativo' checked={ativo || false} onChange={() => setAtivo && setAtivo(!ativo)} />
          Ativo
        </label>
      </div>
      <div>
        <label className='margin-form'>*Item obrigatório</label>
      </div>
    </>
  )
}

EtapaUm.propTypes = {
  nome: PropTypes.string,
  setNome: PropTypes.func.isRequired,
  descricao: PropTypes.string,
  setDescricao: PropTypes.func.isRequired,
  ativo: PropTypes.bool,
  setAtivo: PropTypes.func.isRequired
}

export default EtapaUm
