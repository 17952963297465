import Loading from 'components/loading'
import TabHandler from 'components/produto/tabHandler'
import { Screen } from 'components/style'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { loadProdutos } from 'store/produto/actions'
import { loadProdutos as loadProdutoEstoqueComprador } from 'store/rastreio-comprador/actions'
import { loadRastreioDonatario } from 'store/rastreio-donatario/actions'
import 'styles/product.css'
import TelaCatalogo from '../../pages/catalogo'
import TelaEstoque from '../../pages/estoque'
import TelaRastreioComprador from '../rastreio-comprador'
import TelaRastreioDonatario from '../rastreio-donatario'

function ListProdutos ({
  produtos,
  produtosComprador,
  produtosDonatario,
  categorias,
  dispatch,
  isLoadingProdutos,
  isLoadingProdutosComprador,
  isLoadingProdutosDonatario,
  isClient,
  perfil,
  isDeleting
}) {
  useEffect(() => {
    if (isClient) {
      const { doacao } = perfil
      if (doacao.donatario) {
        dispatch(loadRastreioDonatario())
      } else {
        dispatch(loadProdutoEstoqueComprador())
      }
    } else {
      dispatch(loadProdutos())
    }
  }, [dispatch, isClient, perfil])

  const [currentTab, setCurrentTab] = useState(1)
  const isCatalogo = currentTab === 0
  const renderProduto = () => <TelaCatalogo isCatalogo={isCatalogo} />
  const renderEstoque = () => {
    return <TelaEstoque produtos={produtos} categorias={categorias} isCatalogo={isCatalogo} />
  }

  if (isLoadingProdutos || isDeleting || isLoadingProdutosComprador || isLoadingProdutosDonatario) {
    return <Loading />
  }

  const getTabs = () => [
    {
      display: 'Catálogo',
      callback: renderProduto
    },
    {
      display: 'Estoque',
      callback: renderEstoque
    }
  ]

  const tabs = getTabs()

  return (
    <Screen back={{ to: '/', title: 'Menu' }}>
      {(() => {
        if (isClient) {
          return (
            <div name='catalogo' className='p-3'>
              {(() => {
                if (perfil.doacao.donatario) {
                  return <TelaRastreioDonatario produtosDonatario={produtosDonatario} categorias={categorias} isCatalogo={isCatalogo} />
                } else if (perfil.venda.comprador) {
                  return <TelaRastreioComprador produtos={produtosComprador} categorias={categorias} isCatalogo={isCatalogo} />
                } else {
                  return null
                }
              })()}
            </div>
          )
        } else {
          return <TabHandler tabs={tabs} updateTab={setCurrentTab} />
        }
      })()}
    </Screen>
  )
}

const mapStateToProps = (state) => {
  const { doacao, venda } = state.perfil

  return {
    categorias: state.produto.categorias,
    produtos: state.produto.produtos,
    produtosComprador: state.estoqueComprador.produtos,
    isLoadingProdutos: state.produto.isLoadingProdutos,
    produtosDonatario: state.doacaoRastreioDonatario.produtos,
    isLoadingProdutosDonatario: state.doacaoRastreioDonatario.isLoadingRastreioDonatario,
    isLoadingProdutosComprador: state.estoqueComprador.isLoadingProdutos,
    isDeleting: state.produto.isDeleting,
    perfil: state.perfil,
    isClient: doacao.donatario || venda.comprador
  }
}
const mapDispatchToProps = (dispatch) => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(ListProdutos)
