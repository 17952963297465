import { isArrayNotEmpty, requestApi } from 'util/utils'
import ACTIONS from './reducerTypes'
import { URL_DOACAO_ENTREGA_RASTREIO, URL_DOACAO_ENTREGA_RASTREIO_PENDENTES } from './urls'

export const editRastreioDonatario = (produto) => (dispatch) => {
  dispatch({ type: ACTIONS.MOVRASTREIODONATARIO.LOTE.POST.BEGIN })
  return requestApi(dispatch, URL_DOACAO_ENTREGA_RASTREIO, 'POST', produto)
    .fetch()
    .then((result) => {
      if (result && result.data) {
        dispatch({
          type: ACTIONS.MOVRASTREIODONATARIO.LOTE.POST.SUCCESS,
          payload: result.data
        })
      } else {
        console.error('Erro ao editar estoque:', result.error)
        dispatch({
          type: ACTIONS.MOVRASTREIODONATARIO.LOTE.POST.FAILURE,
          payload: { error: result.error }
        })
      }
    })
    .catch((error) => {
      console.error('Erro ao editar estoque (catch):', error)
      dispatch({
        type: ACTIONS.MOVRASTREIODONATARIO.LOTE.POST.FAILURE,
        payload: { error: error.message }
      })
    })
}

export const loadRastreioDonatario = (perfil) => {
  return (dispatch) => {
    dispatch({ type: ACTIONS.RASTREIODONATARIO.LOAD.BEGIN })
    return requestApi(dispatch, URL_DOACAO_ENTREGA_RASTREIO_PENDENTES)
      .fetch()
      .then(async (result) => {
        if (result && result.data && isArrayNotEmpty(result.data.itens)) {
          const payload = result.data.itens.map((entrega) => ({
            id: entrega.id,
            idEntregaProduto: entrega.entregaProduto.id,
            idProduto: entrega.entregaProduto.idProduto,
            nomeProduto: entrega.entregaProduto.nomeProduto,
            unidadeBase: entrega.entregaProduto.produto.unidadeBase,
            quantidade: entrega.entregaProduto.quantidade,
            quantidadeAtual: entrega.quantidadeAtual,
            vencimento: entrega.entregaProduto.produto.vencimento,
            motivoAtualizacao: entrega.motivoAtualizacao
          }))

          return dispatch({ type: ACTIONS.RASTREIODONATARIO.LOAD.SUCCESS, payload })
        } else {
          return dispatch({ type: ACTIONS.RASTREIODONATARIO.LOAD.SUCCESS, payload: [] })
        }
      })
      .catch((error) => {
        console.error('Erro ao carregar estoque (catch):', error)
        dispatch({
          type: ACTIONS.RASTREIODONATARIO.LOAD.FAILURE,
          payload: { error: error.message }
        })
      })
  }
}
