import { isArrayNotEmpty, requestApi } from 'util/utils'
import ACTIONS from './reducerTypes'
import { URL_VENDA_ENTREGA_RASTREIO, URL_VENDA_ENTREGA_RASTREIO_PENDENTES } from './urls'

export const editRastreioComprador = (produto) => {
  return (dispatch) => {
    dispatch({ type: ACTIONS.MOVRASTREIOCOMPRADOR.LOTE.POST.BEGIN })
    return requestApi(dispatch, URL_VENDA_ENTREGA_RASTREIO, 'POST', produto)
      .fetch()
      .then((result) => {
        if (result && result.data) {
          return dispatch({
            type: ACTIONS.MOVRASTREIOCOMPRADOR.LOTE.POST.SUCCESS,
            payload: result.data
          })
        } else {
          console.error('Erro no resultado da API:', result.status, result.data)
          return dispatch({ type: ACTIONS.MOVRASTREIOCOMPRADOR.LOTE.POST.FAILURE })
        }
      })
  }
}

export const loadProdutos = (perfil) => {
  return (dispatch) => {
    dispatch({ type: ACTIONS.RASTREIOCOMPRADOR.LOAD.BEGIN })
    return requestApi(dispatch, URL_VENDA_ENTREGA_RASTREIO_PENDENTES)
      .fetch()
      .then(async (result) => {
        if (result && result.data && isArrayNotEmpty(result.data.itens)) {
          const entregas = result.data.itens

          const payload = entregas.map((entrega) => {
            const { id, entregaProduto, quantidadeAtual } = entrega
            const { nomeProduto, unidade } = entregaProduto

            return {
              id,
              idEntregaProduto: entregaProduto.id,
              idProduto: entregaProduto.idProduto,
              nomeProduto,
              unidade,
              quantidadeAtual
            }
          })

          const obj = {
            itens: payload
          }

          return dispatch({
            type: ACTIONS.RASTREIOCOMPRADOR.LOAD.SUCCESS,
            payload: obj
          })
        } else {
          console.error('Erro no resultado da API:', result.status, result.data)
          return dispatch({ type: ACTIONS.RASTREIOCOMPRADOR.LOAD.FAILURE })
        }
      })
  }
}
